@use "../abstracts" as a;

.logo-paris-ctn {
  display: flex;
  justify-content: center;
  padding: 25px 0;

  img {
    height: 80px;
    width: auto;
  }

  @media (max-width: a.$content-width) {
    img {
      height: 65px;
    }
  }
}