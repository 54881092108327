$bp-small: 768px;
$bp-medium: 1000px;

.d-none-small{
  @media (max-width: $bp-small){
    display: none;
  }
}
.d-none-medium{
  @media (max-width: $bp-medium){
    display: none;
  }
}