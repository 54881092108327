@use '../../abstracts' as a;

nav.breadcrumb {
  margin-bottom: 30px;
  justify-content: center;

  ul {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    li.breadcrumb-step:last-child p.breadcrumb-title {
      margin-right: 0;
    }

    li.breadcrumb-step {
      align-items: center;
      text-align: center;
      font-size: 16px;
      display: inline-block;

      a {
        text-decoration: none;
        color: a.$blue;
        display: flex;
        align-items: center;
        text-align: center;

        p.breadcrumb-title{
          margin-right: 5px;
          margin-left: 5px;
        }

        span.breadcrumb-number {
          padding: 5px;
          border-radius: 100px;
          border: 1px solid a.$electric-blue;
          color: a.$electric-blue;
          align-items: center;
          height: 25px;
          width: 25px;
          display: inline-flex;
          justify-content: center;
        }

        span.breadcrumb-line {
          height: 1px;
          width: 25px;
          border: 1px solid a.$electric-blue;
          margin-right: 5px;
          @media (max-width: a.$bp-small) {
            margin-right: 0;
            width: 10px;
          }
        }
      }

      a:visited {
        color: inherit
      }
    }

    li.breadcrumb-step.selected a {
      font-weight: bold;
      color: a.$electric-blue;

      span.breadcrumb-number {
        font-weight: bold;
        color: white;
        background-color: a.$electric-blue;
      }
    }

  }
}