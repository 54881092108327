@forward "inhumation";
@forward "result";
@use '../abstracts' as a;

.main {
  line-height: normal;
  max-width: a.$content-width;
  padding: 0;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 0 15px;
  };
}
