@use '../../abstracts' as a;

.checkbox-inline {
  display: flex;
  justify-content: center;

  .form-check {
    margin: 0 3px;
    padding: 0;

    label.form-check-label {
      background-color: a.$electric-blue;
      color: white;
      border: 2px solid a.$electric-blue;
      border-radius: 3px;
      padding: 10px;
      min-width: 200px;
      text-align: center;
      cursor: pointer;
    }

    label.form-check-label:hover {
      background-color: white;
      color: a.$electric-blue;
    }

    input[type="radio"] {
      visibility: hidden;
      position: absolute;
      padding: 0;
      margin: 0;
    }

    input[type="radio"]:checked + label {
      background-color: white;
      color: a.$electric-blue;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .form-check {
      margin: 2px auto;
    }
  }
}