$lightergray: #F0F0F0;
$lightgray: #E0E0E0;
$gray: #707070;
$blue: #011C33;
$electric-blue: #354BCF;
$electric-blue-lighter: #848ccb;
$green: #3ECD2E;
$mint: #42c0bd;
$cyan: #31EEF3;

.color-electric-blue{
  color: $electric-blue;
}