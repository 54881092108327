@use '~bootstrap/scss/bootstrap';
@use "includes";
@use "components";
@use "pages";

body {
  font-family: "Montserrat", Sans-serif, serif;
  margin-right: auto;
  margin-left: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0;
  }
}
