@use "../abstracts" as a;

footer {
  background-color: a.$blue;
  margin-top: auto;

  .social-ctn {
    margin: 5px auto 20px auto;
    display: flex;
    justify-content: space-between;
    width: 70px;

    img {
      height: 25px;
      filter: invert(88%) sepia(88%) saturate(0%) hue-rotate(187deg) brightness(110%) contrast(97%);
    }
  }

  .label-ctn {
    margin: 0 auto;
    padding-bottom: 25px;
    display: flex;
    justify-content: space-between;
    width: 230px;

    img {
      height: 42px;
    }
  }

  .footer-menu-ctn {
    text-align: center;
    margin-bottom: 25px;

    a {
      color: white;
      font-size: 14px;
    }
  }
  .copyright{
    padding-bottom: 15px;
    p {
      text-align: center;
      color: white;
      font-size: 10px;
    }
  }
}