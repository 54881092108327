@forward "checkbox";
@forward "select";
@forward "radio";
@forward "select-img";

.field-ctn, #help-notice {
  max-width: 500px;
  margin: 0 auto 15px auto;
}

.field-ctn {
  label {
    font-size: 16px;
  }
}

textarea::placeholder {
  font-size: 14px;
}