@use '../../abstracts' as a;

.products-ctn{
  margin-bottom: 40px;
  max-width: 1100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 30px;
  place-content: center;
  @media (max-width: a.$bp-medium){
    grid-template-columns: repeat(1, 1fr);
    max-width: 768px;
  }
  .product-card{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    border: 1px solid a.$lightergray;

    img{
      width: auto;
      max-height: 200px;
    }
    figcaption{
      text-align: center;
      font-weight: bold;
    }
  }
  .product-card-selected{
    border: 1px solid a.$electric-blue;
  }
}