@use "../abstracts" as a;

.form-ctn {
  min-height: 35vh;
  min-width: 400px;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    min-width: unset;
  };

  h2 {
    color: a.$electric-blue;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  h3{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: left;
  }

  .label-ctn {
    display: flex;
    justify-content: center;
  }

  .form-title{
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .text-ctn{
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    font-weight: 500;
    p{
      font-size: 14px;
      span.bold{
        font-weight: 600;
      }
    }
    dl{
      dt{
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
      }
      dd{
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

