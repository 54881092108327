@use "../abstracts" as a;

header {
  margin-bottom: 60px;

  h1, p {
    color: a.$blue;
  }

  .page-title-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    max-width: 1100px;
    margin: 0 auto;

    img {
      width: 100%;
      max-width: 700px;
      max-height: 300px;
      object-fit: cover;
      object-position: center;
    }

    .title {
      text-align: center;
      line-height: normal;
      background-color: white;
      padding: 10px 25px;
      margin-top: -20px;

      h1{
        margin: 0;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
      }

      @media screen and (max-width: a.$content-width) {
        h1 {
          font-size: 22px;
        }
      }
    }
  }
}