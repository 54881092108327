
    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('../font/Montserrat-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 100;
    src:  url('../font/Montserrat-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('../font/Montserrat-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 200;
    src: url('../font/Montserrat-ExtraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('../font/Montserrat-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url('../font/Montserrat-LightItalic.woff') format('woff');
    }

    @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        src: url('../font/Montserrat-Regular.woff') format('woff');
        }
        
    
    @font-face {
        font-family: 'Montserrat';
        font-style: italic;
        font-weight: 400;
        src: url('../font/Montserrat-Italic.woff') format('woff');
        }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../font/Montserrat-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: url('../font/Montserrat-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../font/Montserrat-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    src: url('../font/Montserrat-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../font/Montserrat-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('../font/Montserrat-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../font/Montserrat-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    src: url('../font/Montserrat-ExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('../font/Montserrat-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    src: url('../font/Montserrat-BlackItalic.woff') format('woff');
    }