@use '../../abstracts/colors' as c;

.btn-ctn {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;

  button, a {
    margin: 0 5px;
    min-width: 220px;
    max-width: 300px;
  }
  #cta-phone{
      max-width: 500px;
  }


  @media (max-width: 768px) {
    flex-direction: column;
    button, a {
      margin: 5px auto;
    }
  };
}

.btn-ctn.btn-ctn-column {
  flex-direction: column;

  button, a {
    margin: 5px auto;
  }
}

.nav-btn-ctn {
  margin-top: 30px;
}

.btn {
  min-width: 70px;
  border-radius: 3px;
  font-weight: 500;
  align-content: center;
  line-height: 20px;
  padding: 10px 25px;
}

.btn-primary {
  background-color: c.$electric-blue;
}

.btn-secondary {
  background-color: c.$electric-blue-lighter;
  border-color: c.$electric-blue-lighter;
}

.btn-tertiary {
  background-color: c.$mint;
  border-color: c.$mint;
  color: white;
}

.btn-tertiary:hover {
  color: c.$mint;
  border-color: c.$mint;
}

.btn-quaternary {
  background-color: c.$cyan;
  border-color: c.$cyan;
  color: white;
}

.btn-quaternary:hover {
  color: c.$cyan;
  border-color: c.$cyan;
}

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  i{
    margin-right: 10px;
  }
}
