@use '../../abstracts' as a;
#help-notice {
  margin: 20px auto 10px auto ;
  padding: 15px;
  border: 4px solid a.$electric-blue;

  dt, dd {
    font-size: 14px;
    font-weight: 500;
  }
  dt{
    color: a.$electric-blue;
    margin-bottom: 5px;
    font-weight: bold;
  }
  dd{
    margin-bottom: 2px;
  }
}